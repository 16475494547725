<template>
    <v-container fluid>
        <!-- DESKTOP -->
        <v-row class="d-none d-lg-block mx-16 px-16">
            <v-col cols="12">
                <span class="primary--text text-h1 font-weight-bold">{{$t('technologies.title')}}</span>
            </v-col>
        </v-row>
        <!-- MOBILE -->
        <v-row class="d-lg-none">
            <v-col cols="12">
                <span class="primary--text text-h4 font-weight-bold">{{$t('technologies.title')}}</span>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">           
            <v-col cols="12">
                <carousel-3d ref="my-carousel" :autoplay="true" :autoplay-timeout="3000" class="ma-0 pa-0" :width="300" :height="310" :perspective="50" :disable3d="false" :display="5" :space="365" :controls-visible="false">
                    <slide v-for="(i, index) in images" :index="index" :key="index" class="transparent elevation-10">
                        <v-container fluid class="fill-height">
                            <v-img 
                                :src="i"
                                contain
                            ></v-img>
                        </v-container>
                    </slide>                    
                </carousel-3d>
            </v-col>
            <v-col cols="12" class="mt-n12 text-center ma-0 pa-0 mb-8">
                <v-btn
                    class="mx-6"
                    fab
                    dark
                    small
                    color="primary"
                    @click="goLeft()"
                    >
                    <v-icon dark color="white">
                        mdi-arrow-left-thick
                    </v-icon>
                </v-btn>
                
                <v-btn
                    class="mx-6"
                    fab
                    dark
                    small
                    color="primary"
                    @click="goRight()"
                    >
                    <v-icon dark color="white">
                        mdi-arrow-right-thick
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<style>
.carousel-3d-container{
    height: 400px !important;
}
.carousel-3d-slider{
    margin-top: 25px !important;
    margin-bottom: 25px !important;
}
.line-1 {
  height: 2px;
  background: black;
  width: 50%;
  margin: auto;
}
</style>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default{
    name: 'Technologies',
    components:{
        Slide,
        Carousel3d
    },
    data(){
        return{
            images:[
                require('../assets/technologies/laravel.png'),
                require('../assets/technologies/javascript.png'),
                require('../assets/technologies/vuejs.png'),
                require('../assets/technologies/reactjs.png'),
                require('../assets/technologies/php.png'),
                require('../assets/technologies/sql.png'),
                require('../assets/technologies/mssql.png'),
                require('../assets/technologies/mysql.png'),
                require('../assets/technologies/git.png'),
                require('../assets/technologies/azure.png'),
                require('../assets/technologies/mongo.png'),
                require('../assets/technologies/digital_ocean.png'),
            ],
            showTitles: false
        }
    },
    methods:{
        goLeft(){
            this.$refs['my-carousel'].goPrev()
        },
        goRight(){
            this.$refs['my-carousel'].goNext()
        }
    }
}
</script>