<template>
    <v-app-bar class="elevation-2 primary" fixed>
        <v-avatar size="48">
            <img
                src="@/assets/icons/icon_P BRANCO 3.png"
                alt="John"
            >
            </v-avatar>
            <v-spacer></v-spacer>
            <p v-if="$route.name != 'Privacy'" class="mt-4"><a class="font-weight-light white--text"        @click="getHome">{{ $t('menuv2.home') }}</a></p>
            <p v-if="$route.name != 'Privacy'" class="ml-16 mt-4"><a class="font-weight-light white--text"  @click="$router.push('/' + $i18n.locale + '/home/utility')">{{ $t('menuv2.utility') }}</a></p>   
            <p v-if="$route.name != 'Privacy'" class="ml-16 mt-4"><a class="font-weight-light white--text"  @click="$router.push('/' + $i18n.locale + '/home/portfolio')">{{ $t('menuv2.portfolio') }}</a></p>   
            <p v-if="$route.name != 'Privacy'" class="ml-16 mt-4"><a class="font-weight-light white--text"  @click="$router.push('/' + $i18n.locale + '/home/team')">{{ $t('menuv2.team') }}</a></p>
            <p v-if="$route.name != 'Privacy'" class="ml-16 mt-4"><a class="font-weight-light white--text"  @click="$router.push('/' + $i18n.locale + '/home/technologies')">{{ $t('menuv2.technologies') }}</a></p> 
            <p v-if="$route.name != 'Privacy'" class="ml-16 mt-4"><a class="font-weight-light white--text"  @click="$router.push('/' + $i18n.locale + '/contacts')">{{ $t('menuv2.contacts') }}</a></p>  
            <p v-if="$route.name != 'Privacy'" class="ml-16 mt-4"><a class="font-weight-light white--text"  @click="$router.push('/' + $i18n.locale + '/blog')">{{ $t('menuv2.blog') }}</a></p>   
            <p class="mt-4 ml-6">               
            <v-btn
                color="black"
                icon
                @click="switchLocale()"
            >
                <flag :iso="chooseLanguage()" class="elevation-10"/>
            </v-btn>
        </p>
        </v-app-bar>
</template>
<style>
.v-btn__content{
    width: fit-content;
    height: fit-content;
}
.fi{
    background-size: cover !important;
    line-height: 1em !important;
}
.fi.fis {
    height: 50% !important;
    width: 50% !important;
}
</style>
<script>
export default{
    data(){
        return{
            locales: process.env.VUE_APP_I18N_FALLBACK_LOCALE.split(',')
        }
    },
    methods:{
        getHome(){
            window.location.href = '/' + this.$i18n.locale
        },
        switchLocale(){
            this.$emit('switchLocale')
        },
        chooseLanguage(){
            if(this.$i18n.locale == 'pt')
                return 'gb';

            return 'pt'
        }
    }
}
</script>