import BaseApi from "./BaseApi";

export default class User extends BaseApi{
    construct() {}
    
    static async login(data) {
        return User.startRequest().then(() => {
            let url = BaseApi.getApiUrl() + "login";


            return User.getAxiosInstance().post(url, data);
        });
    }

    static async logout(id) {
        let url = BaseApi.getApiUrl() + "logout";

        return User.getAxiosInstance().post(url, {user: id});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/users/";
    }
}