<template>
    <div>        
        <v-dialog
            v-model="dialog"
            :fullscreen="false"
            persistent
            max-width="1000px"
            max-height="auto"
        >   
            <v-app-bar color="#002733">
                <div class="text-center" style="color: white; width: 100%;">
                    <h1>{{ $t('career.title') }}</h1>
                </div>
                <v-spacer></v-spacer>
                <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
            </v-app-bar>
            <v-container style="background-color: white;">
                <v-row>
                    <v-card class="elevation-0" width="100%">
                        <v-card-title>Aceitamos candidaturas espontâneas! Envia a tua candidatura para:</v-card-title>
                        <v-card-subtitle>candidaturas@dreamdealtech.pt</v-card-subtitle>
                        <v-col cols="12">
                            <v-data-table
                                :headers="headers"
                                :items="items"
                                :server-items-length="total"
                                :hide-default-header="true"
                                :expanded.sync="expanded"
                                show-expand
                                :hide-default-footer="true"
                                :loading="loading"
                                :footer-props="tableFooter"
                                :options.sync="options"
                                class="elevation-4"
                                locale="pt-pt"
                                single-expand
                            >
                            <template v-slot:item.position="{ item }">
                                <p class="font-weight-bold mt-4">{{ item.position }}</p>
                            </template>

                            <template v-slot:expanded-item="{ item }">   
                                <v-row class="mt-8 ml-4 mb-4" style="position: relative;">
                                    <v-col cols="12">
                                        <v-card class="elevation-0">
                                            <v-card-title>
                                                Job description
                                            </v-card-title>
                                        <v-card-text>
                                            {{ item.job_description }}
                                        </v-card-text>
                                        <v-card-title>Qualifications:</v-card-title>
                                        <v-card-text>
                                            <ul v-for="q in item.qualifications" :key="q">
                                                <li>{{ q }}</li>
                                            </ul>
                                        </v-card-text>
                                        <v-card-title>Good to have:</v-card-title>
                                        <v-card-text>
                                            <ul v-for="q in item.good_to_have" :key="q">
                                                <li>{{ q }}</li>
                                            </ul>
                                        </v-card-text>
                                        <v-card-title>Conditions:</v-card-title>
                                        <v-card-text>
                                            <ul v-for="q in item.conditions" :key="q">
                                                <li>{{ q }}</li>
                                            </ul>
                                        </v-card-text>
                                        <v-card-title>What's in it for you:</v-card-title>
                                        <v-card-text>
                                            <ul v-for="q in item.for_you" :key="q">
                                                <li>{{ q }}</li>
                                            </ul>
                                        </v-card-text>
                                        <v-card-title>What you can expect:</v-card-title>
                                        <v-card-text>
                                            <ul v-for="q in item.expect" :key="q">
                                                <li>{{ q }}</li>
                                            </ul>
                                        </v-card-text>
                                    </v-card>
                                    </v-col>
                                </v-row>
                            </template>

                            </v-data-table>
                        </v-col>
                    </v-card>
                </v-row>
            </v-container>
        </v-dialog>
    </div>
</template>
<script>
export default{
    data(){
        return{
            dialog: false,
            expanded: [],
            loading: false,
            searching: false,
            tableFooter: {
            'items-per-page-options': [5, 10, 15, 20],
            'items-per-page-text': "Linhas por página"
            },
            options: {},
            headers: [
            {
                text: 'Position',
                sortable: false,
                value: 'position',
            },
            {
                text: '',
                align: 'end',
                value: 'data-table-expand'
            }
            ],
            total: 1,
            items: [
                {

                    'id': 1,
                    'position': 'Sales consultant',
                    'job_description': "Estamos à procura de um Consultor de Vendas motivado e experiente para se juntar à nossa equipa de vendas. Como consultor de vendas, será responsável por ajudar os nossos clientes a compreender os nossos produtos e serviços, bem como trabalhar em estreita colaboração com eles para encontrar soluções personalizadas que atendam às suas necessidades.O candidato ideal deve ter excelentes habilidades de comunicação e vendas, bem como alguma experiência comprovada em atendimento ao cliente. Espera-se que o candidato tenha conhecimento em técnicas de vendas e estratégia.",
                    'qualifications':[
                        'Facilidade em trabalhar com programas informáticos;',
                        'Desenvolver e manter relacionamentos de longo prazo com os clientes;',
                        'Compreender as necessidades e objetivos dos clientes e apresentar soluções personalizadas;',
                        'Alcançar metas de vendas mensais e trimestrais',
                        'Fortes habilidades de comunicação'
                    ],
                    'good_to_have':[
                        'Experiencia com métodos ageis',
                        'Experiencia no mercado de IT',
                        'Experiencia comprovada'
                    ],
                    'conditions':[
                        'Salário ajustável à experiencia.',
                        'Bonus de performance.'
                    ],
                    'for_you':[
                        'Um espaço onde poderá trazer impacto e fazer crescer a empresa!'
                    ],
                    'expect':[
                        'Uma conversa com os nossos recrutadores.',
                        'entrevista com o nosso CEO.'
                ]
                    /*
                    'id': 1,
                    'position': 'Junior Developer',
                    'job_description': "We deeply believe in the utility of our vision for a better world. Illimited access to critical information in the palm of your hand. We, as a startUp in an embryonic phase, want your contribution to make that vision come alive. We are a young company who believes in work and social life balance. Fully remote and flexible hours.",
                    'qualifications':[
                        'Have a university degree or equivalent certification, in Computer Science, Information Systems or verified Programming experience (we are happy to see your portfolio..',
                        'Have Experience in PHP and JavaScript.',
                        'Familiar with relational databases',
                        'Good organization and documentation skills.',
                        'Familiar with agile methods.',
                        'Have interest in process and system efficiency, IT controls, transforming the way technologies help the consumer'
                    ],
                    'good_to_have':[
                        'Experience with Laravel Framework',
                        'Experience with Gitlab',
                        'Experience with Agile methods'
                    ],
                    'conditions':[
                        'Paid internship or Working terms can be adjusted based on your experience.',
                        'An oportunity to really make an impact on the growth of the company.'
                    ],
                    'for_you':[
                        'An oportunity to really make an impact on the growth of the company.',
                        'Develop your own personal purpose and help us create a positive ripple effect on our projects and costumers.',
                        'Accelerate your technical capabilities in a almost no constraint space.'
                    ],
                    'expect':[
                        'A first conversation over the phone with one of our recruiters.',
                        'Interview process with our CEO.'
                    ]
                    */
                },
            ]
        };
    },
    methods:{
        openDialog(){
            this.dialog = true
        }
    }
}
</script>