<template>
    <v-container fluid>
        <!-- DESKTOP -->
        <v-row class="d-none d-lg-block mx-16 px-16">
            <v-col cols="12">
                <span class="primary--text text-h1 font-weight-bold">{{$t('testimonials.title')}}</span>
            </v-col>
        </v-row>
        <!-- MOBILE -->
        <v-row class="d-lg-none">
            <v-col cols="12">
                <span class="primary--text text-h4 font-weight-bold">{{$t('testimonials.title')}}</span>
            </v-col>
        </v-row>

        <!-- DESKTOP -->
        <div class="d-none d-lg-block">
        
            <v-card width="80%" style="margin: auto; display: block;" class="elevation-0 mt-8 mb-8">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">                
                            <span class="mx-12 px-12 text-h1 font-weight-black secondary--text">“</span><br/>
                            <div class="mx-16 px-16 mt-n12 text-justify">
                                <span class="font-weight-bold text-h6" style="color: #052A59; font-family: Montserrat !important; font-weight: bold !important;">
                                    {{$t('testimonials.edirio')}}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="end">
                        <v-col cols="12" md="3" class="mx-12 px-12">
                            <v-card class="elevation-0">
                                <v-img style="cursor: pointer;" @click="openWindow('https://www.edirio.pt/')" src="@/assets/portfolio/edirio.png" contain></v-img>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-divider></v-divider>

            <v-card width="80%" style="margin: auto; display: block;" class="elevation-0 mt-8 mb-8">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">                
                            <span class="mx-12 px-12 text-h1 font-weight-black secondary--text">“</span><br/>
                            <div class="mx-16 px-16 mt-n12 text-justify">
                                <span class="font-weight-bold text-h6" style="color: #052A59; font-family: Montserrat !important; font-weight: bold !important;">
                                    {{$t('testimonials.elocal')}}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="end">
                        <v-col cols="12" md="3" class="mx-12 px-12">
                            <v-card class="elevation-0 mt-8">
                                <v-row>
                                    <v-col cols="12">
                                        <span @click="openWindow('https://e-local.pt/')" class="text-h4 font-weight-bold mr-2" style="cursor: pointer; float: right;">E-Local</span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span @click="openWindow('https://e-local.pt/')" class="mr-2 mt-n8" style="cursor: pointer; float: right;">www.e-local.pt</span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>

    <!-- MOBILE -->
    <div class="d-lg-none">
        <v-card width="80%" style="margin: auto; display: block;" class="elevation-0 mt-8">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">                
                            <span class="text-h1 font-weight-black secondary--text">“</span><br/>
                            <div class="mt-n12">
                                <span class="font-weight-bold text-h6" style="color: #052A59; font-family: Montserrat !important; font-weight: bold !important;">
                                    {{$t('testimonials.edirio')}}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="end">
                        <v-col cols="12" md="3">
                            <v-card class="elevation-0">
                                <v-img style="cursor: pointer;" @click="window.open('https://www.edirio.pt/', '_blank')" src="@/assets/portfolio/edirio.png" contain></v-img>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-divider></v-divider>

            <v-card width="80%" style="margin: auto; display: block;" class="elevation-0 mt-8 mb-8">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">                
                            <span class="text-h1 font-weight-black secondary--text">“</span><br/>
                            <div class=" mt-n12">
                                <span class="font-weight-bold text-h6" style="color: #052A59; font-family: Montserrat !important; font-weight: bold !important;">
                                    {{$t('testimonials.elocal')}}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="end">
                        <v-col cols="12" md="3">
                            <v-card class="elevation-0 mt-8">
                                <v-row>
                                    <v-col cols="12">
                                        <span @click="openWindow('https://e-local.pt/')" class="text-h4 font-weight-bold mr-2" style="cursor: pointer; float: right;">E-Local</span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span @click="openWindow('https://e-local.pt/')" class="mr-2 mt-n8" style="cursor: pointer; float: right;">www.e-local.pt</span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
    </div>

    </v-container>
</template>
<script>
export default{
    name: 'Testimonials',
    methods:{
        openWindow(h){
            window.open(h, '_blank')
        }
    }
}
</script>