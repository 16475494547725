<template>
    <div class="d-flex align-center justify-center" style="height: 100vh">
        <v-sheet width="400" class="mx-auto">
            <v-form fast-fail @submit.prevent="submit">
                <v-text-field  variant="underlined" v-model="username" label="User Name"></v-text-field>

                <v-text-field variant="underlined" v-model="password" label="password" type="password"></v-text-field>

                <v-btn type="submit" variant="outlined" color="primary" block class="mt-2">Sign in</v-btn>

            </v-form>
            
        </v-sheet>
    </div>
</template>
<script>
import LoginAPI from '@/api/User.js'

export default {
    data() {
        return {
            username: '',
            password: '',
        };
    },
    mounted(){
        localStorage['auth_dreamdeal_website'] = 0;
    },
    methods: {
        submit(){
            
            LoginAPI.logout(null)
            .then(() => {
              this.login();
            }).catch(() => {
              this.login();
            })
        },
        login() {
            let pwd_enc = this.$encryptValue(this.password);

            let login_data = {
                username: this.username,
                password: pwd_enc,
                place: 'client_website',
                client_id: process.env.VUE_APP_CLIENT_ID
            }
            
            LoginAPI.login(login_data)
                .then(() => {

                    localStorage['auth_dreamdeal_website'] = 1;
            
                    this.$router.push('/' + this.$i18n.locale + '/backend');

                }).catch(err => {

                    console.log("Login inválido: " + err)
                });
        },
    },
}
</script>