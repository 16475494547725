<template>
    <v-container fluid v-if="!loading">
        <v-card>
            <v-card-title>Assistente de artigos</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-btn color="error" @click="$router.push('/' + $i18n.locale + '/backend/article')">Criar</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Artigo
                                    </th>
                                </tr>
                                <th class="text-left">
                                    Data
                                </th>
                                <th class="text-left">
                                    Opções
                                </th>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in articles_array"
                                :key="item[$i18n.locale].id"
                                >
                                    <td>{{ item[$i18n.locale].title }}</td>
                                    <td>{{ item[$i18n.locale].data }}</td>
                                    <td>
                                        <v-btn color="success" @click="openArticle(item)">Ver</v-btn>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import LoginAPI from '@/api/User.js'
import articles from '@/json_articles/articles.json'

export default{
    name: 'BackendEditor',
    data(){
        return{
            loading: true,
            articles_array: []
        }
    },
    mounted(){
        if(localStorage['auth_dreamdeal_website'] == 0){
            this.$router.push('/' + this.$i18n.locale + '/backend/login')
            return
        }
        this.articles_array = articles.articles

        this.loading = false
    },
    methods:{
        openArticle(item){
            this.$router.push('/' + this.$i18n.locale + '/backend/' + item[this.$i18n.locale].id)
        },
        logout(){
            LoginAPI.logout(null)
            .then(() => {
                localStorage['auth_dreamdeal_website'] = 0;
                this.$router.push('/')
            }).catch(() => {
            })
        }
    }
}
</script>