<template>
    <v-container fluid style="background: linear-gradient(#043339, #D9D9D9, #052947); min-height: 100vh;">
       
        
        <!-- DESKTOP -->   
        <v-container class="d-none d-lg-block">
            <div class="triangle"></div>
           
            <div class="green-triangle"></div>
            <div class="gray-triangle"></div>
        
            <div class="orange-triangle2"></div>
            <div class="beje-triangle"></div>
            <v-row class="py-16">
                <v-col cols="12" class="px-16 ma-0 pa-0">
                    <v-card class="elevation-0 px-16 transparent">
                        <v-card-title>
                            <v-btn dark rounded style="float: right; background-color: rgba(255, 255, 255, 0.7);" class="accent--text" @click="$router.push('/' + $i18n.locale + '/blog')">
                                <v-icon class="mr-2">mdi-arrow-left</v-icon>{{$t('blog.back')}}
                            </v-btn>
                        </v-card-title>
                        <v-col cols="12">
                            <v-img max-height="600px" class="align-center" contain :aspect-ration="16/9" style="margin: auto;" 
                            :src="require('@/json_articles/images/' + article.id + '/' + article.foto)"
                            :lazy-src="require('@/json_articles/images/' + article.id + '/' + article.foto)">    
                            </v-img>                        
                        </v-col>
                            
                        <v-card-title 
                                class="text-h3 primary--text font-weight-bold text-uppercase text-left" 
                                style="white-space: pre-wrap !important; word-break: keep-all;">
                                {{ article.title }}
                            </v-card-title>
                            <v-card-subtitle 
                                class="text-h4 primary--text text-left">
                                {{ article.subtitle }}
                            </v-card-subtitle>
                        <v-card-text class="text-justify primary--text font-weight-medium mt-12 transparent" 
                            style="background-color: rgba(255, 255, 255, 1); font-size: 18px"
                            >
                            <v-container fluid v-html="article.body" class="pa-10 paper">
                            </v-container>
                        </v-card-text>
                        
                        <v-card-subtitle style="" class="text-caption">
                                <v-divider color="white"></v-divider>
                                <v-spacer></v-spacer>
                                <div class="mt-8">
                                    <v-row class="ml-2">
                                        <v-col cols="auto" class="ma-0 pa-0">                                            
                                            <v-avatar>
                                                <img
                                                    :src="require('@/json_articles/autores/' + article.autor_photo)"
                                                    alt="John"
                                                >
                                            </v-avatar>
                                        </v-col>
                                        <v-col cols="auto" class="ma-0 pa-0">
                                            <div>
                                                <span class="font-weight-bold ml-2 white--text">({{ article.autor }} - {{ article.data }})</span>
                                                <br/>
                                                <gb-social-button 
                                                    v-if="article.author_id!=null"
                                                    @click="openSocialNetwork('linkedin', $getSocialNetwork(article.author_id, 'Linkedin'))" 
                                                    network="linkedin" size="small" 
                                                    :reverse="true" theme="light" 
                                                    style="border: none;" >
                                                </gb-social-button>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- MOBILE -->   
        <v-container class="d-md-none">
            <v-row class="py-16">
                <v-col cols="12" class="ma-0 pa-0">
                    <v-card class="elevation-0 transparent">                        
                        <v-card-title>
                            <v-btn dark rounded style="float: right; background-color: rgba(255, 255, 255, 0.7);" class="accent--text" @click="$router.push('/' + $i18n.locale + '/blog')">
                                <v-icon class="mr-2">mdi-arrow-left</v-icon>Voltar
                            </v-btn>
                        </v-card-title>

                        <v-col cols="12">
                            <v-img max-height="600px" class="align-center" cover :aspect-ration="16/9" style="margin: auto;" 
                            :src="require('@/json_articles/images/' + article.id + '/' + article.foto)"
                            :lazy-src="require('@/json_articles/images/' + article.id + '/' + article.foto)">    
                            </v-img>                        
                        </v-col>
                            
                        <v-card-title 
                                class="text-h5 primary--text font-weight-bold text-uppercase" 
                                style="white-space: pre-wrap !important; word-break: keep-all;">
                                {{ article.title }}
                            </v-card-title>
                            <v-card-subtitle 
                                class="text-h6 primary--text">
                                {{ article.subtitle }}
                            </v-card-subtitle>
                        <v-card-text class="text-justify primary--text font-weight-medium text-body-1 font-weight-light mt-12">
                            <v-container fluid v-html="article.body" class="pa-10 paper">
                            </v-container>
                        </v-card-text>
                        
                        <v-card-subtitle style="" class="text-caption">
                                <v-divider></v-divider>
                                <v-spacer></v-spacer>
                                <div class="mt-8">
                                    <v-row no-gutters justify="center">
                                        <v-col cols="10" class="ma-0 pa-0">                                            
                                            <v-avatar style="margin: auto; display: block;">
                                                <img
                                                    :src="require('@/json_articles/autores/' + article.autor_photo)"
                                                    alt="John"
                                                >
                                            </v-avatar>
                                        </v-col>
                                        <v-col cols="10">
                                            <v-col cols="auto" class="ma-0 pa-0 mt-2">
                                            <div style="display: block; margin: auto;" class="text-center">
                                                <span class="font-weight-bold ml-2 white--text">({{ article.autor }} - {{ article.data }})</span>
                                                <br/>
                                                <gb-social-button 
                                                    v-if="article.author_id!=null"
                                                    @click="openSocialNetwork('linkedin', $getSocialNetwork(article.author_id, 'Linkedin'))" 
                                                    network="linkedin" size="small" 
                                                    :reverse="true" theme="light" 
                                                    style="border: none; margin: auto; display: block;" >
                                                </gb-social-button>
                                            </div>
                                        </v-col>
                                    </v-col>
                                        
                                    </v-row>
                                </div>
                            </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<style>
.triangle {
  width: 0;
  height: 0;
  border: 350px solid transparent;
  border-top: 0;
  border-bottom: 600px solid #E98459;
  position: absolute;
  left: -15vh;
  margin-top: 0vh;
  z-index: 0;
}
.paper {
  background: #fff;
  box-shadow:
    /* The top layer shadow */
    0 -1px 1px rgba(0,0,0,0.15),
    /* The second layer */
    0 -10px 0 -5px #eee,
    /* The second layer shadow */
    0 -10px 1px -4px rgba(0,0,0,0.15),
     /* The third layer */
    0 -20px 0 -10px #eee,
    /* The third layer shadow */
    0 -20px 1px -9px rgba(0,0,0,0.15);
    /* Padding for demo purposes */
    padding: 30px;
}

.green-triangle {
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-top: 0;
  border-bottom: 120px solid #0A4E56;
  position: absolute;
  rotate: -110deg;
  left: 90%;
  top: 30%;
  z-index: 1;
}

.beje-triangle {
  width: 0;
  height: 0;
  border: 30px solid transparent;
  border-top: 0;
  border-bottom: 80px solid #EAEAE9 ;
  position: absolute;
  rotate: 110deg;
  left: 0;
  top: 75%;
  z-index: 1;
}

.gray-triangle {
  width: 0;
  height: 0;
  border: 30px solid transparent;
  border-top: 0;
  border-bottom: 80px solid #858585;
  position: absolute;
  rotate: 60deg;
  left: 90%;
  margin-top: 10vh;
  z-index: 1;
}

.orange-triangle2 {
  width: 0;
  height: 0;
  border: 30px solid transparent;
  border-top: 0;
  border-bottom: 80px solid #E76012;
  position: absolute;
  rotate: 15deg;
  left: 10%;
  top: 50%;
  z-index: 1;
}


</style>

<script>
import ARTICLES from '@/json_articles/articles.json'

export default{
    watch:{
        '$i18n.locale':{
            immediate: true,
            handler(val){
                if(val == undefined)
                    return 

                this.loading = true
                this.fillData()
            }
        }
    },
    data(){
        return{
            loading: true,
            article: null
        }
    },
    mounted(){
        this.fillData()   
        window.scrollTo(0,0)     
    },
    methods:{
        openSocialNetwork(social, link){
            if(social == 'linkedin')
                window.open(link, '_blank')
        },
        fillData(){
            this.loading = true
            let id = this.$route.params.id
            ARTICLES.articles.forEach(element => {
            if(element[this.$i18n.locale].id == id)
                this.article = element[this.$i18n.locale]
            });

            this.loading = false
        }
    }
}
</script>