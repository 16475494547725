<template>
  <v-container fluid class="ma-0 pa-0">
    <!-- DESKTOP -->
    <v-container fluid class="d-none d-lg-block primary white--text text-center text-h6" style="font-family: Montserrat;">
      <v-row style="position: absolute; left: 0; bottom: 0;" class="ma-0 pa-0 ml-2 mb-2">
        <v-btn dark text @click="openComplaint">{{$t('complaint_portal')}}</v-btn>
      </v-row>
      {{ new Date().getFullYear() }} — <strong>{{ $t('developed_by') }} Dreamdeal Technologies</strong>
      
      <v-row
            style="position: absolute; right: 0; bottom: 0;" class="ma-0 pa-0"
      >
        <v-btn text dark @click="openPrivacy">{{$t('privacy_label')}}</v-btn>
        <v-btn
            v-for="sn in social_networks"
            :key="sn.icon"
            class="mb-2"
            dark
            icon
        >          
          <v-icon size="28px" @click="goTo(sn)">
            {{ sn.icon }}
          </v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <!-- MOBILE -->
    <v-container fluid class="d-lg-none primary white--text text-center text-caption" style="font-family: Montserrat;">
      <v-row>
        <v-col cols="12">
          <v-btn
            v-for="sn in social_networks"
            :key="sn.icon"
            dark
            icon
          >
            
            <v-icon size="28px" @click="goTo(sn)">
              {{ sn.icon }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" class="mt-n4">
          {{ new Date().getFullYear() }} — <strong>{{ $t('developed_by') }} Dreamdeal Technologies</strong>
        </v-col>
        <v-col cols="12" class="mt-n4">
          <v-btn x-small text dark @click="openPrivacy">{{$t('privacy_label')}}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
  <script>
  export default {
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      social_networks:[
        {
            icon: 'mdi-linkedin',
            url:  'https://www.linkedin.com/company/dreamdeal-technologies/'
        },
        {
            icon: 'mdi-facebook',
            url:  'https://www.facebook.com/dreamdealtech'
        }
      ]
    }),
    methods:{
      openComplaint(){
        window.open('https://complaint.ignoto.eu/cfca53e394dd7223-dd8817ade4a29960', '_blank');
      },
      openPrivacy(){
        window.open('/' + this.$i18n.locale + '/privacy', '_blank');
      },
      goTo(social){
        window.open(social.url)
      }
    }
  }
</script>