<template>
    <v-container fluid class="secondary top-image" style="height: 100vh; width: 100%;">
        <v-card class="d-none d-lg-block transparent elevation-0" style="top: 40%;">
            <v-card-text>
                <v-img
                    style="margin: auto; display: block; "
                    src="@/assets/logos/logo_final_whitetext_Prancheta 1.svg"
                    lazy-src="@/assets/logos/logo_final_whitetext_Prancheta 1.svg"
                    width="50%"
                >
            </v-img>
            </v-card-text>
            <v-card-text class="text-center text-h6 font-weight-bold white--text">
                <v-row no-gutters justify="center">
                    <v-col cols="12">
                        <span style="font-family: Montserrat">{{ $t('intro.p1')}}</span>
                    </v-col>
                    <v-col cols="12">
                        <span style="font-family: Montserrat">{{ $t('intro.p2')}}</span>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        
        <!-- MOBILE -->
        <v-card class="d-lg-none transparent elevation-0" style="top: 20%;">
            <v-card-text>
                <v-img
                    style="margin: auto; display: block; "
                    src="@/assets/logos/logo_final_whitetext_Prancheta 1.svg"
                    lazy-src="@/assets/logos/logo_final_whitetext_Prancheta 1.svg"
                >
                </v-img>
            </v-card-text>
            <v-card-text class="text-center text-h6 font-weight-bold white--text">
                <v-row no-gutters justify="center">
                    <v-col cols="12">
                        <span style="font-family: Montserrat">{{ $t('intro.p1')}}</span>
                    </v-col>
                    <v-col cols="12">
                        <span style="font-family: Montserrat">{{ $t('intro.p2')}}</span>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<style>
.top-image{
    background: url('../assets/covers/topo.svg') no-repeat;
    background-size: 100%;
    margin-top: -5%;
}
</style>
<script>
export default{
    mounted(){
        this.expand = true
    },
    watch:{
        '$i18n.locale'(){
            this.reloadAnime++
        }
    },
    data(){
        return{
        }
    }
}
</script>