<template>
    <v-container fluid style="background: linear-gradient(#043339, #D9D9D9, #052947); min-height: 100vh;">
        <!-- DESKTOP -->
        <div class="d-none d-lg-block">
            <v-row class="mt-10 mx-16 px-16">
                <v-col cols="12" md="8">
                    <span style="font-family: Montserrat !important;" class="text-h1 white--text font-weight-bold">"Meet our blog</span>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field 
                        hide-details 
                        filled 
                        rounded 
                        dense 
                        single-line 
                        prepend-inner-icon="mdi-magnify" class="shrink mx-4 white mt-8"
                        v-model="searchText"
                        @blur="search"
                        >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-img width="70px" style="z-index: 100; position: fixed; right: 0; top: 20%;" src="@/assets/covers/laterais.svg"></v-img>  
            <v-container style="height: 100vh;" v-if="getArticles.length == 0">
                    <span class="text-h2 primary--text text-center py-16" style="margin: auto; display: block;">(Sem resultados)</span>
            </v-container>
            <v-row v-for="(article, index) in getArticles" :key="article.id" justify="center" no-gutters class="ma-0 pa-0">
                <div class="triangle" v-if="drawBigTriangle(index) && getArticles.length > 1"></div>
                <div v-if="drawGrayGreen(index)">
                    <div class="green-triangle"></div>
                    <div class="gray-triangle"></div>
                </div>
                <div v-if="drawWhiteOrange(index)">
                    <div class="orange-triangle"></div>
                    <div class="beje-triangle"></div>
                </div>
                <v-col cols="10" class="py-10 my-10" style="z-index: 3;">
                    <v-card height="500px" class="elevation-0 transparent">
                        <v-row class="ma-0 pa-0">
                            <v-col cols="6" class="ma-0 pa-0">
                                <v-container fluid fill-height>
                                <v-img
                                    height="500px" 
                                    class="rounded-xl elevation-24"
                                    :src="require('@/json_articles/images/' + article.id + '/' + article.foto)"
                                    cover
                                    :aspect-ratio="16/9"
                                ></v-img>  
                                </v-container>
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                                <v-card class="elevation-0 transparent ma-0 pa-0 fill-height">
                                    <v-card-title class="white--text font-weight-black text-uppercase text-h4" style="word-break: keep-all;" v-html="article.title">
                                        
                                    </v-card-title>
                                    <v-card-subtitle class="white--text font-weight-black text-h6" style="word-break: keep-all;" v-html="article.subtitle">
                                        
                                    </v-card-subtitle>
                                    <v-card-text class="white--text text-body-1" style="word-break: keep-all;" v-html="textArticle(article)">
                                    </v-card-text>
                                    <v-card-actions style="bottom: 0; position: absolute; width: 100%;">
                                        <div class="text-subtitle-1" style="width: 100%;">
                                            <v-row class="ml-2">
                                                <v-col cols="auto" class="ma-0 pa-0">                                            
                                                    <v-avatar>
                                                        <img
                                                            :src="require('@/json_articles/autores/' + article.autor_photo)"
                                                            alt="John"
                                                        >
                                                    </v-avatar>
                                                </v-col>
                                                <v-col cols="auto" class="ma-0 pa-0">
                                                    <div>
                                                        <span class="font-weight-bold ml-2 white--text">{{ article.autor }}</span>
                                                        <br/>
                                                        <gb-social-button 
                                                            v-if="article.author_id!=null"
                                                            @click="openSocialNetwork('linkedin', $getSocialNetwork(article.author_id, 'Linkedin'))" 
                                                            network="linkedin" size="small" 
                                                            :reverse="true" theme="light" 
                                                            style="border: none;" >
                                                        </gb-social-button>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            
                                            <v-btn @click="$router.push('/' + $i18n.locale + '/blog/article/' + article.id)" style="float: right; background-color: #094A59;" class="white--text">{{$t('blog.see_more')}}</v-btn>
                                        </div>
                                    </V-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-container fluid>            
                <v-btn v-if="getArticles.length > 0 && articles.length > maximumLoad" style="margin: auto; display: block;" color="#567E9F" class="white--text font-weight-bold" @click="maximumLoad += maximumLoad">Continuar a ler</v-btn>
            </v-container>
        </div>



        <!-- MOBILE -->
        <div class="d-lg-none">
            <v-row>
                <v-col cols="12" class="text-center mt-10">
                    <span style="font-family: Montserrat !important;" class="text-h4 white--text font-weight-bold">"Meet our blog</span>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field 
                        hide-details 
                        filled 
                        rounded 
                        dense 
                        single-line 
                        prepend-inner-icon="mdi-magnify" class="shrink mx-4 white mt-8"
                        v-model="searchText"
                        @blur="search"
                        >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-img width="25px" style="z-index: 100; position: fixed; right: 0; top: 20%;" src="@/assets/covers/laterais.svg"></v-img>  
            <v-container v-if="getArticles.length == 0">
                    <span class="text-h2 primary--text text-center py-16" style="margin: auto; display: block;">(Sem resultados)</span>
            </v-container>
            <v-row v-for="(article) in getArticles" :key="article.id" class="mt-16">
                
                <v-col cols="12" style="z-index: 3;" class="my-16 py-16">
                    <v-card class="elevation-0 transparent">                      
                        <v-card class="elevation-0 transparent">
                            <v-img
                                height="250px" 
                                :src="require('@/json_articles/images/' + article.id + '/' + article.foto)"
                                cover
                                :aspect-ratio="16/9"
                            ></v-img>  
                            <v-card-title class="white--text font-weight-black text-uppercase text-h5 text-center" v-html="article.title">
                                
                            </v-card-title>
                            <v-card-subtitle class="white--text font-weight-black text-h6" v-html="article.subtitle">
                                
                            </v-card-subtitle>
                            <v-card-text class="white--text text-body-1" v-html="textArticle(article, true)">
                            </v-card-text>
                            <v-card-actions style="width: 100%;">
                                <div class="text-subtitle-1" style="width: 100%;">
                                    <v-row class="ml-2">
                                                <v-col cols="auto" class="ma-0 pa-0">                                            
                                                    <v-avatar>
                                                        <img
                                                            :src="require('@/json_articles/autores/' + article.autor_photo)"
                                                            alt="John"
                                                        >
                                                    </v-avatar>
                                                </v-col>
                                                <v-col cols="auto" class="ma-0 pa-0">
                                                    <div>
                                                        <span class="font-weight-bold ml-2 white--text">{{ article.autor }}</span>
                                                        <br/>
                                                        <gb-social-button 
                                                            v-if="article.author_id!=null"
                                                            @click="openSocialNetwork('linkedin', $getSocialNetwork(article.author_id, 'Linkedin'))" 
                                                            network="linkedin" size="small" 
                                                            :reverse="true" theme="light" 
                                                            style="border: none;" >
                                                        </gb-social-button>
                                                    </div>
                                                </v-col>
                                                
                                            </v-row>
                                            <v-row no-gutters justify="center" class="mt-4">
                                                <v-col cols="6">
                                                    <v-btn block style="background-color: #094A59;" class="white--text" @click="$router.push('/' + $i18n.locale + '/blog/article/' + article.id)">{{$t('blog.see_more')}}</v-btn>
                                                </v-col>
                                            </v-row>
                                    
                                            
                                </div>
                            </V-card-actions>
                        </v-card>
                    </v-card>
                </v-col>
            </v-row>
            <v-container fluid>            
                <v-btn v-if="getArticles.length > 0 && articles.length > maximumLoad" style="margin: auto; display: block;" color="#567E9F" class="white--text font-weight-bold" @click="maximumLoad += maximumLoad">Continuar a ler</v-btn>
            </v-container>
        </div>
    </v-container>
</template>
<style>
.gb-social-button__focuser{
  border: none !important;
}


.triangle {
  width: 0;
  height: 0;
  border: 350px solid transparent;
  border-top: 0;
  border-bottom: 600px solid #E98459;
  position: absolute;
  left: -15vh;
  margin-top: 20vh;
  z-index: 2;
}

.green-triangle {
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-top: 0;
  border-bottom: 120px solid #0A4E56;
  position: absolute;
  rotate: -110deg;
  left: 20vh;
  z-index: 1;
}

.gray-triangle {
  width: 0;
  height: 0;
  border: 30px solid transparent;
  border-top: 0;
  border-bottom: 80px solid #858585;
  position: absolute;
  rotate: 60deg;
  left: 10vh;
  margin-top: 10vh;
  z-index: 1;
}

.orange-triangle {
  width: 0;
  height: 0;
  border: 30px solid transparent;
  border-top: 0;
  border-bottom: 80px solid #E76012;
  position: absolute;
  rotate: 15deg;
  left: 10vh;
  z-index: 1;
}

.beje-triangle {
  width: 0;
  height: 0;
  border: 30px solid transparent;
  border-top: 0;
  border-bottom: 80px solid #EAEAE9 ;
  position: absolute;
  rotate: 110deg;
  left: 0;
  z-index: 1;
}
</style>

<script>
import ARTICLES from '@/json_articles/articles.json'

export default{
    components:{
    },
    mounted(){
        this.articles = ARTICLES.articles
        this.search()
        window.scrollTo(0,0)
    },
    data(){
        return{            
            positionY: 0,
            logo_img: require("../assets/logo_dream.png"),
            reloadAnime: 0,
            expand: false,
            quantity: 2,
            canvasBgColor: 1,
            maximumLoad: 5,
            stepperLoad: 6,
            searchText: '',
            dataArticles: [],
            articles: [],
        }
    },    
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods:{
        openSocialNetwork(social, link){
            if(social == 'linkedin')
                window.open(link, '_blank')
        },
        search(){
            let dataArray = []
            
            this.articles.forEach(element => {
                if(element[this.$i18n.locale].title.includes(this.searchText))
                    dataArray.push(element)
            });
            this.dataArticles = dataArray
        },
        handleScroll () {
            // Any code to be executed when the window is scrolled
            this.positionY = window.scrollY
        },
        loadMore(){
            this.maximumLoad = this.maximumLoad + this.stepperLoad
        },
        textArticle(t, mobile=false){
            let aux = t.body.replaceAll('<br/>', '');

            let max_size = 400
            if(mobile)
                max_size = 500

            if(aux.length > max_size)
                return aux.substring(0, max_size) + '...'

            return aux
        },  
        drawBigTriangle(num) {
            if(num == 0)
                return true
            
            let fizzbuzz = (num % 5 == 0)
            return fizzbuzz
        },
        drawGrayGreen(num) {
            if(num == 0)
                return false
            
            let fizzbuzz = (num % 3 == 0)

            return fizzbuzz
        },
        drawWhiteOrange(num) {
            if(num == 0)
                return false
            
            let fizzbuzz = (num % 4 == 0)


            return fizzbuzz
        }
    },
    computed:{
        getArticles(){
            let finalArticles = []
            this.dataArticles.forEach(element => {
                finalArticles.push(element[this.$i18n.locale])
            });
            return finalArticles.slice(0, this.maximumLoad)
        },
        getDataArticles(){
            return this.dataArticles
        }
    },
}
</script>