<template>
    <v-container fluid v-if="!loading">
        <v-card>
            <v-card-title>Artigo</v-card-title>
            <v-card-text>
                <v-stepper v-model="e1" class="elevation-0">
                    <v-stepper-header>
                    <v-stepper-step
                        :complete="e1 > 1"
                        step="1"
                    >
                        Definir dados globais
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="e1 > 2"
                        step="2"
                    >
                        Conteúdo do artigo
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Configurações
                    </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                    <v-stepper-content step="1">                        
                        <v-row class="mt-4">                   
                            <v-col cols="12" md="12">
                                <v-text-field :disabled="disabledArticle" v-model="globalVars.articleID" outlined label="Dá um ID único ao teu artigo"></v-text-field>
                            </v-col>            
                            <v-col cols="12" md="4">
                                <v-text-field v-model="globalVars.data" outlined label="Data"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" align="end">
                                <v-btn large :disabled="globalVars.articleID == null || globalVars.data == null" color="success" @click="addID">                                    
                                    Seguinte<v-icon class="ml-2">mdi-arrow-right-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2" v-if="globalVars.articleID != null">
                        <v-row align="center">
                            <v-col cols="12" class="text-center">
                                <span class="text-h3">Escreve o teu artigo</span>
                            </v-col> 
                        </v-row>
                        <v-tabs v-model="tab" background-color="primary" dark centered fixed-tabs class="mt-4">
                            <v-tabs-slider color="yellow"></v-tabs-slider>
                            <v-tab v-for="language in languages" :key="language">{{language}}</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item v-for="language in languages" :key="language.language">
                                <v-row v-if="!lockedArticle" class="mt-8">
                                    <v-col cols="12">
                                        <v-text-field v-model="article[language].title" outlined label="Título do artigo"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="article[language].subtitle" outlined label="Sub-titulo do artigo"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-tiptap v-model="article[language].body" />
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>

                        <v-row>
                            <v-col cols="12" align="end" class="mt-4">
                                <v-btn @click="e1 = 1" large color="error">Voltar<v-icon class="ml-2">mdi-arrow-left-circle</v-icon></v-btn>
                                <v-btn @click="e1 = 3" large color="success">Seguinte<v-icon class="ml-2">mdi-arrow-right-circle</v-icon></v-btn>
                            </v-col>
                        </v-row>

                    </v-stepper-content>

                    <v-stepper-content step="3" v-if="globalVars.articleID != null">
                        <v-card class="elevation-0">
                            <v-card-title class="text-center">
                                    <v-alert
                                    dense
                                    border="left"
                                    type="error"
                                    style="width: 100%;"
                                >
                                    <span class="text-h4">ATENÇÃO!</span><br/>Para a imagem ser bem colocada, certifica-te de criares uma pasta com o nome <br/><span class="text-h6"><strong>"{{getFolderName()}}"</strong></span><br/> dentro da pasta <br/><span class="text-h6"><strong>"src/json_articles/images"</strong></span>
                                </v-alert>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" md="2">
                                        <v-select
                                            :items="autores"
                                            outlined
                                            label="Autor"
                                            item-value="id"
                                            :item-text="item => item.autor"
                                            v-model="globalVars.autor"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field v-model="globalVars.photo" label="Insira o caminho da imagem"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        
                        <v-row>
                            <v-col cols="12" align="end">
                                <v-btn @click="e1 = 2" large color="error">Voltar<v-icon class="ml-2">mdi-arrow-left-circle</v-icon></v-btn>
                                <v-btn @click="submit()" large color="success">Concluir<v-icon class="ml-2">mdi-check-circle</v-icon></v-btn>
                            </v-col>
                        </v-row>

                    </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>                
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import articles from '@/json_articles/articles.json'

export default{
    name: "Article",
    data(){
        return{
            disabledArticle: true,
            autores:[
                {
                    id: 'Erbi',
                    autor: 'Erbi Silva',
                    autor_photo: 'erbi.jpeg',
                    author_id: 'Erbi'
                },
                {
                    id: 'Alvaro',
                    autor: 'Alvaro Vaz',
                    autor_photo: 'alvaro_new.png',
                    author_id: 'Alvaro'
                },
            ],
            e1: 1,
            tab: 0,
            globalVars:{
                articleID: null,
                data: null,
                photo: null,
                autor: null
            },
            languages: [],
            lockedArticle: true,
            article: {
                "pt":{
                    "id": "",
                    "data":"",
                    "title": "",
                    "subtitle": "",
                    "body": "",
                    "foto": "",
                    "autor_id":"",
                    "autor": "",
                    "author_id":"",
                    "autor_photo": ""
                },
                "en":{
                    "id": "",
                    "data":"",
                    "title": "",
                    "subtitle": "",
                    "body": "",
                    "foto": "",
                    "autor_id":"",
                    "autor": "",
                    "author_id":"",
                    "autor_photo": ""
                }
            },
            loading: true
        }
    },
    mounted(){
        if(this.$route.params.article_id != 'article'){
            this.disabledArticle = true
            this.loadArticle()
        }
        else this.disabledArticle = false

        this.languages = process.env.VUE_APP_I18N_FALLBACK_LOCALE.split(',')


        this.loading = false
    },
    methods:{
        getFolderName(){
            let full_name = this.globalVars.articleID.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            full_name = full_name.replaceAll(' ', '-')

            return full_name
        },
        downloadJSON(){
                const data = JSON.stringify(articles)
                const blob = new Blob([data], {type: 'text/plain'})
                const e = document.createEvent('MouseEvents'),
                a = document.createElement('a');
                a.download = "articles.json";
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);                
            
        },
        submit(){
            let selectedAutor = null;


            this.autores.forEach(element => {
                if(element.id == this.globalVars.autor)
                    selectedAutor = element
            });

   
            
            this.languages.forEach(element => {
                this.article[element].foto = this.globalVars.photo
                this.article[element].autor_id = selectedAutor.id
                this.article[element].autor = selectedAutor.autor
                this.article[element].author_id = selectedAutor.author_id
                this.article[element].autor_photo = selectedAutor.autor_photo

            });

            
            if(this.$route.params.article_id != 'article'){
                this.languages.forEach(element => {
                    articles.articles.forEach(ar => {
                        if(ar[element].id == this.$route.params.article_id)
                            ar[element] = this.article[element]
                    });
                });
            }
            else articles.articles.unshift(this.article)

            this.downloadJSON()
        },
        addID(){            
            let aux = this.getFolderName()

            this.globalVars.articleID = aux

            this.languages.forEach(element => {
                this.article[element].id = this.globalVars.articleID
            });

            this.lockedArticle = false


            this.e1 = 2
        },
        loadArticle(){
            console.log("GO")
            let articles_array = articles.articles
            articles_array.forEach(element => {
                if(element[this.$i18n.locale].id == this.$route.params.article_id)
                    this.article = element;
            });
            this.globalVars.data = this.article[this.$i18n.locale].data
            this.globalVars.articleID = this.article[this.$i18n.locale].id
        }
    }
}
</script>