<template>
    <v-app>
        <v-app-bar
            app
            class="primary"
        >
            <v-toolbar fluid class="elevation-0 primary">
                <v-btn class="success" @click="$router.push('/' + $i18n.locale + '/backend')">Ver artigos</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="logout()">Logout</v-btn>
            </v-toolbar>
        </v-app-bar>
        <v-main>
          <v-container fluid class="ma-0 pa-0">
                <router-view></router-view>
          </v-container>
        </v-main>
    </v-app>
</template>
<script>
import LoginAPI from '@/api/User.js'

export default{
    data(){
        return{
            languages: [],
            selectedLanguage: null,
            downloadFile: false
        }
    },
    mounted(){
        this.selectedLanguage = this.$i18n.locale = this.$route.params.lang
    },
    methods:{
        downloadJsonFile(){
            this.downloadFile = true
        },
        changeLanguage(c){
            this.$i18n.locale = c
        },
        logout(){
            LoginAPI.logout(null)
            .then(() => {
                localStorage['auth_dreamdeal_website'] = 0;
                this.$router.push('/')
            }).catch(() => {
            })
        }
    }
}
</script>