<template>
        <v-app>
          
              <MenuDesktop class="d-none d-lg-block" @changeTab="changeTab" @switchLocale="switchLocale" @career="careerFunc"/>
              <MenuMobile class="d-lg-none" @openMenu="openMenu" @career="careerFunc"/>
           

            <v-main>
              <v-container fluid class="ma-0 pa-0">
                    <router-view :menuTab="menuTab" @change="changeTab"></router-view>
                    <Career ref="Career"/>
                    <Cookie :snackbar="showWindowCookie" @cookie-accept="cookieAccept" @cookie-reject="cookieReject" />
              </v-container>
            </v-main>

            <v-expand-transition>
                <v-scroll-y-reverse-transition v-if="mobileMenuOpen" class="ma-0 pa-0">
                    <v-container fluid style="position: fixed; bottom: 0; z-index: 100;" class="ma-0 pa-0">
                        <v-card class="white--text rounded-xl ma-0 pa-0">
                            <v-container fluid class="primary text-uppercase font-weight-bold text-h6">
                                <span class="text-white">Menu</span>
                                <v-btn style="float: right;" text @click="mobileMenuOpen = false;">
                                    <v-icon x-large class="text-white font-weight-bold" color="white">mdi-close</v-icon>
                                </v-btn>
                            </v-container>
                        <v-card-text class="primary text-uppercase font-weight-bold">
                            <v-row>
                                <v-col cols="12" class="text-left">
                                  <v-row no-gutters justify="center">
                                    <v-col cols="1">
                                      <div style="height: 30px; width: 30px; clip-path: polygon(0 9%, 0 90%, 100% 55%); background-color: #85A2AD;"></div>
                                    </v-col>
                                    <v-col :cols="window.width < 400 ? 11 : 3">                                      
                                      <v-btn text class="white--text" @click="getHome">
                                          {{$t('menuv2.home')}}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" class="text-left">
                                  <v-row no-gutters justify="center">
                                    <v-col cols="1">
                                      <div style="height: 30px; width: 30px; clip-path: polygon(0 78%, 99% 81%, 55% 0); background-color: #1C3C42;"></div>
                                    </v-col>
                                    <v-col :cols="window.width < 400 ? 11 : 3">                            
                                      <v-btn text class="white--text" @click="$router.push('/home/utility'); mobileMenuOpen = false;">
                                          <div>{{$t('menuv2.utility')}}</div>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" class="text-left">
                                  <v-row no-gutters justify="center">
                                    <v-col cols="1">
                                      <div style="height: 30px; width: 30px; clip-path: polygon(0 78%, 100% 96%, 69% 0); background-color: #C9D2DA;"></div>
                                    </v-col>
                                    <v-col :cols="window.width < 400 ? 11 : 3">                                   
                                      <v-btn text class="white--text" @click="$router.push('/home/portfolio'); mobileMenuOpen = false;">
                                          {{$t('menuv2.portfolio')}}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" class="text-left">
                                  <v-row no-gutters justify="center">
                                    <v-col cols="1">
                                      <div style="height: 30px; width: 30px; clip-path: polygon(0 29%, 100% 87%, 100% 19%); background-color: #E98459;"></div>
                                    </v-col>
                                    <v-col :cols="window.width < 400 ? 11 : 3">                                 
                                      <v-btn text class="white--text" @click="$router.push('/home/team2'); mobileMenuOpen = false;">
                                          {{$t('menuv2.team')}}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" class="text-left">
                                  <v-row no-gutters justify="center">
                                    <v-col cols="1">
                                      <div style="height: 30px; width: 30px; clip-path: polygon(100% 96%, 100% 4%, 31% 66%); background-color: #E76012;"></div>
                                    </v-col>
                                    <v-col :cols="window.width < 400 ? 11 : 3">                                     
                                      <v-btn text class="white--text" @click="$router.push('/home/technologies'); mobileMenuOpen = false;">
                                          {{$t('menuv2.technologies')}}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" class="text-left">
                                  <v-row no-gutters justify="center">
                                    <v-col cols="1">
                                      <div style="height: 30px; width: 30px; clip-path: polygon(96% 89%, 98% 8%, 6% 94%); background-color: #052947;"></div>
                                    </v-col>
                                    <v-col :cols="window.width < 400 ? 11 : 3">                                      
                                      <v-btn text class="white--text" @click="$router.push('/contacts'); mobileMenuOpen = false;">
                                          {{$t('menuv2.contacts')}}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" class="text-left">
                                  <v-row no-gutters justify="center">
                                    <v-col cols="1">
                                      <div style="height: 30px; width: 30px; clip-path: polygon(96% 89%, 12% 91%, 13% 27%); background-color: #7F8B9B;"></div>
                                    </v-col>
                                    <v-col :cols="window.width < 400 ? 11 : 3">                                      
                                      <v-btn text class="white--text" @click="$router.push('/' + $i18n.locale + '/blog'); mobileMenuOpen = false;">
                                          {{$t('menuv2.blog')}}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        </v-card>
                    </v-container>
                </v-scroll-y-reverse-transition>
            </v-expand-transition>

            <v-footer 
              padless
              fluid
              class="ma-0 pa-0"
              color="secondary"
              fill-height>
              <Footer />
            </v-footer>
            
        </v-app>
        
</template>
<style>
.menu-triangle {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  rotate: -90deg;
}
</style>
<script>
  import Footer from "@/views/Footer.vue"
  import MenuDesktop from '@/components/Desktop/Menu';
  import Career from '@/views/Career';
  import MenuMobile from '@/components/Mobile/Menu';
  import Cookie from '@/components/cookies/Cookie.vue'

  export default {
    components:{
      Footer,
      MenuDesktop,
      Career,
      MenuMobile,
      Cookie
    },
    watch:{
      menuTab:{
        immediate: true,
        handler(val){
          if(val == null)
            return
          if(val == undefined)
            return

          if(val == '')
            return

          if(this.$route.name == 'Blog'){
            this.$router.push('/')
          }

          if(val == 'blog'){
            this.menuTab = val
            this.$router.push('/' + this.$i18n.locale + '/blog')
            return
          }

          this.menuTab = val
        }
      }
    },
    data(){
      return{
        menuTab: '',
        locales: process.env.VUE_APP_I18N_FALLBACK_LOCALE.split(','),
        mobileMenuOpen: false,
        window:{
          width: 0,
          height: 0
        },
        showCookieBar: false
      }
    },
    mounted(){
      let cookie = this.$cookies.get('dreamdeal_cookie');
      //this.$cookies.set('dreamdeal_cookie', JSON.stringify(cookie), '1s');
      if(cookie == null)
        this.showCookieBar = true
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        
        let language = this.$route.params.lang
        if(language != undefined)
          this.$i18n.locale = language
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods:{
      cookieReject(){
        this.$cookies.set('dreamdeal_cookie', null, '1s');
        this.showCookieBar = false
      },
      cookieAccept(ob){
        this.$cookies.set('dreamdeal_cookie', JSON.stringify(ob), '7d');
        this.showCookieBar = false
      },
      handleResize() {
          this.window.width = window.innerWidth;
          this.window.height = window.innerHeight;
      },
      getHome(){
        window.location.href = '/' + this.$i18n.locale
      },
      openMenu(){
        this.mobileMenuOpen = !this.mobileMenuOpen
      },
      careerFunc(){
        this.$gtag.event('career_click', {
          'event_category': 'Carreira',
          'event_label': 'Carreira',
          'value': 'career'
        })
        this.$refs.Career.openDialog()
      },
      changeTab(t){
        this.menuTab = t
      },
      getVersion(){
              return process.env.VUE_APP_SECRET_VERSION
      },
      goTo(place){
        if(place != 'contacts' && place != 'blog'){
          this.$router.push('/' + place)
          return
        }
        this.$router.push(place)
      },
      switchLocale(){
          if(this.$i18n.locale == 'pt')
              this.$i18n.locale = 'en';
          else this.$i18n.locale = 'pt'


          this.$route.params.lang = this.$i18n.locale
          this.$router.push({name: this.$router.currentRoute.name, params: this.$route.params})
      },
      chooseLanguage(){
          if(this.$i18n.locale == 'pt')
              return 'gb';

          return 'pt'
      }
    },
    computed:{      
      showWindowCookie(){
        return this.showCookieBar
      },
    }
  }
</script>