<template>
  <v-app-bar
    app
    class="primary"
  >
    <v-toolbar fluid class="elevation-0 primary">
      <v-btn
          color="black"
          icon
          left
          @click="switchLocale()" 
      >
          <flag :iso="chooseLanguage()" class="elevation-10"/>
      </v-btn>
      <v-spacer></v-spacer>
      <v-scale-transition>
        <v-img
          class="shrink"
          style="margin: auto; display: block;"                  
          src="@/assets/logo_dream.png"
          transition="scale-transition"
          v-show="showHeader"
          width="100"
          contain
        />
      </v-scale-transition>
        <v-spacer></v-spacer>
        
        <v-btn icon right @click="$emit('openMenu')" >
            <v-icon x-large color="secondary">mdi-menu</v-icon>
        </v-btn>
    </v-toolbar>
  </v-app-bar>    
</template>
<script>
  export default {
    data: () => ({ 
      value: 'recent',
      showHeader: false
    }),
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods:{
      handleScroll(){
          if(window.top.scrollY > 400)
              this.showHeader = true
          else this.showHeader = false
      },
      topPosition(){
        window.scrollTo(0,0);
      },      
      chooseLanguage(){
          if(this.$i18n.locale == 'pt')
              return 'gb';

          return 'pt'
      },
      switchLocale(){
          if(this.$i18n.locale == 'pt')
              this.$i18n.locale = 'en';
          else this.$i18n.locale = 'pt'
      },
    }
  }
</script>