import social from '@/json_articles/social_networks/social.json'
function getSocialNetwork(user_id, socialNetwork) {
    let users = social.users;

    let founded = '';
    users.forEach(element => {
        if(element.name == user_id){
            element.social.forEach(s => {
                if(s.name == socialNetwork)
                    founded = s.url
            });
        }
    });
    return founded;
}
  
export default getSocialNetwork