<template>
  <router-view></router-view>
</template>

<script>
export default {
  components: {
  },

  data: () => ({
  }),
};
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat');
#app {
  font-family: 'Montserrat', sans-serif;
}

html::-webkit-scrollbar, body::-webkit-scrollbar{
  display: none;
}
</style>